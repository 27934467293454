import domutil from "./lib/domutil.js";
import icons from "./resources/icons.js";
import {getString} from "./resources/strings.js";

export default class {
  constructor(options) {
    this.view = options.view
    this.filter = options.filter
    this.layout = options.layout
    this.result = null
    this.results = []
    

    const detailul = domutil.createElement("ul", "better-autocomplete")

    this.header = domutil.createElement("li", "ssDetailHeader")
    detailul.append(this.header)

    this.content = domutil.createElement("li", "ssDetailBody")
    domutil.addClass(this.content, "ssActive")
    detailul.append(this.content)

    this.container = domutil.createElement("div", "ssDetails")
    this.container.append(detailul)

    domutil.removeClass(this.container, "ssActive")

  }
  
  show (){
    if (typeof this.result !== 'undefined' && this.result !== null)
      domutil.addClass(this.container, "ssActive")
  }

  hide() {
    domutil.removeClass(this.container, "ssActive")
  }

  close() {
    if(this.result !== undefined && this.result !== null) {
      this.results = []
      this.hide()
      this.clear()
    }
  }

  setMaxHeight(intHeight) {
    let myIntHeight = intHeight - this.header.offsetHeight
    domutil.css(this.content, "max-height", myIntHeight + 'px')
    domutil.css(this.content, "overflow", 'auto')
  }

  clear() {
    if(this.result !== undefined && this.result !== null) {
      this.result = null
      this.layout.clear()

      while(this.header.firstChild) {
        this.header.removeChild(this.header.firstChild)
      }
      while(this.content.firstChild) {
        this.content.removeChild(this.content.firstChild)
      }
    }
  }
  
  back() {
    if (this.results.length > 0) {
      const result = this.results.pop()
      this.view.resultSelect(result)
      this.render(result)
    }
  }

  setResult(result) {
    if (this.result !== result) {
      if(this.result !== undefined && this.result !== null)
        this.results.push(this.result)

      this.render(result)
    }
  }



  async render(result) {
    this.clear()
    this.result = result
    let backButton
    let title
    if (this.results.length > 0) {
      title = this.results[this.results.length - 1].title
      backButton = domutil.createElement("div", "ssBackButton")
      let icon = domutil.createImageElement(icons.details.back, title)
      backButton.append(icon)
      let titleEl = domutil.createElement("div", "ssTxt")
      titleEl.innerText = title
      backButton.append(titleEl)
      backButton.addEventListener("click", ()=>{
        this.back()
      })
    }else{
      backButton = domutil.createElement("div", "ssBackButton")
      let icon = domutil.createImageElement(icons.details.back, getString("close"))
      backButton.append(icon)
      let titleEl = domutil.createElement("div", "ssTxt")
      titleEl.innerText = getString("close")
      backButton.append(titleEl)
      backButton.addEventListener("click", ()=>{
        this.close()
      })
    }
    this.header.append(backButton)

    let output = domutil.createElement("div", "ssResult")
    domutil.setAttribute(output, "aria-hidden", true)
    let imageContainer = domutil.createElement("div", "ssResultImage")
    if (result.hasOwnProperty("image") && result.image !== null && result.image !== "") {
      imageContainer.append(domutil.createImageElement(result.image))
      output.append(imageContainer)
    }else{
      let searcher = result.searcher
      if (searcher.hasOwnProperty("iconURI") && searcher.iconURI !== null && searcher.iconURI !== "")
        result.image = searcher.iconURI
      else
        result.image = icons.result.defaultIcon

      imageContainer.append(domutil.createImageElement(result.image))
      output.append(imageContainer)
    }
    output.append(imageContainer)

    let titleEl = domutil.createElement("div", "ssResultTitle")
    titleEl.innerText = result.title
    output.append(titleEl)

    if (!this.view.showDescriptionInMore && result.hasOwnProperty("description") && result.description !== null) {
      let description = domutil.createElement("div", "ssResultDescription")
      description.innerText = result.description
      domutil.setAttribute(description, "title", result.description + "' \"" )
      output.append(description)
      domutil.setAttribute(this.header, "aria-label", result.description)
    } else {
      let resultDescription = domutil.createElement("div", "ssResultDescription")
      domutil.addClass(resultDescription, 'hide')
      output.append(resultDescription)
    }

    this.header.append(output)

    let ui = await this.layout.render(result, this.filter)
    this.content.append(ui)
    this.show()
  }
  
  getElement() {
    return this.container
  }
}

