/**
 * @module
 */
import View from './View.js'
import AccordionDetailsLayout from './AccordionDetailsLayout.js'
import DetailItemsRenderer from './DetailItemsRenderer.js'

/**
 *
 * Default UI for Septima Search
 * @extends module:js/defaultview/View
 *
 * @example
 * var view = new DefaultView(
 *   {
 *     input : 'septimasearch',
 *     placeholder : 'Search'
 *   });
 * @sspath Septima.Search
 * */
export default class extends View {

  constructor(options={}) {
    options.detailsLayout =  new AccordionDetailsLayout(
      {
        detailItemsRenderer: new DetailItemsRenderer()
      }
    )
    
    super(options)
  }

}
