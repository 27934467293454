/**
 * @module
 */
import Searcher from "../Searcher.js"
import ResultType from "../../ResultType.js"
import GeoJSON from "ol/format/GeoJSON.js"
import { WFS } from "ol/format.js"
import { intersects as intersectsFilter } from 'ol/format/filter.js'
import GML3 from "ol/format/GML3.js"
import GeoSearch from "../geosearch/GeoSearch.js"
import { getWKTParser } from "../../util/getWKTParser.js"
import Cache from "../../util/Cache.js"
import { reproject } from "../../util/reproject.js"
import DataApiFetcher from "../data-api/Fetcher.js"
/**
 * Søger bygninger i datafordeleren.bbr
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.Datafordeler.EjendomsSearcher
 *   _options:
 *     fetcher:
 *       _type: Septima.Search.Datafordeler.Fetcher
 *       _options:
 *         ...
 * @sspath Septima.Search.Datafordeler
 **/
export default class EjendomsSearcher extends Searcher {
  /**
   *
   * @param {Object} options EjendomsSearcher expects these properties:
   * @param {string} options.fetcher  
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   */
  //https://ki.bbr.dk/kodelister-bbr/0/1/0/Livscyklus

  constructor(options = {}) {
    let defaultOptions = {
      usesGeoFunctions: true,
      kommunekode: '*',
      showExamples: false
    }
    let finalOptions = Object.assign({}, defaultOptions, options)
    super(finalOptions)

    this.fetcher = finalOptions.fetcher

    this.source = "DAF"

    this.geoSearcher = new GeoSearch({})

    this.showExamples = finalOptions.showExamples

    this.types = {
      "eksempler": new ResultType({ id: "eksempler", singular: "Eksempel på ejendomssøgning", plural: "Eksempler på ejendomssøgninger", queryBehaviour: "none", geometrySupport: "none" }),
      "sfe": new ResultType({ id: "sfe", singular: "Samlet fast ejendom", plural: "Samlede faste ejendomme", queryBehaviour: "match", geometrySupport: "hasGeometry" }),
      "ejl": new ResultType({ id: "ejl", singular: "Ejerlejlighed", plural: "Ejerlejligheder", queryBehaviour: "match", geometrySupport: "hasGeometry" }),
      "bfg": new ResultType({ id: "bfg", singular: "Bygning på fremmed grund", plural: "Bygninger på fremmed grund", queryBehaviour: "match", geometrySupport: "hasGeometry" }),
      "samvurdering": new ResultType({ id: "samvurdering", singular: "Samvurdering", plural: "Samvurderinger", queryBehaviour: "none", geometrySupport: "hasGeometry" }),
      "bfenummer": new ResultType({ id: "bfenummer", singular: "bfeNummer", plural: "bfeNumre", queryBehaviour: "none", geometrySupport: "none" }),
    }
    this.registerType(this.source, this.types.sfe)
    this.registerType(this.source, this.types.ejl)
    this.registerType(this.source, this.types.bfg)
    this.registerType(this.source, this.types.samvurdering)
    if (this.showExamples)
      this.registerType(this.source, this.types.eksempler)
    this.registerType(this.source, this.types.bfenummer)

    this.authParamsDatafordeler = finalOptions.authParamsDatafordeler || {
      username: this.fetcher.username,
      password: this.fetcher.password
    }

    this.kommunekoder = []
    if (finalOptions.kommunekode !== '*')
      this.kommunekoder = ("" + finalOptions.kommunekode).split(' ')

    this.cache = new Cache({ ttl: 1000, maxEntries: 40 })
    this.wktParser = getWKTParser()
    this.dataapifetcher = new DataApiFetcher()
  }

  async fetchData(query, caller) {
    let queryResult = this.createQueryResult()
    if (this.showExamples) {
      if (query.target.source === this.source && query.target.type === this.types.eksempler.id && query.queryString.length < 6) {
        queryResult.addNewQuery(this.source, this.types.eksempler.id, '2025129 (Gyldigt BFE-nummer)', 'Samlet fast ejendom med ét matrikelnummer (Hvidovre)', '2025129')
        queryResult.addNewQuery(this.source, this.types.eksempler.id, '2024547 (Gyldigt BFE-nummer)', 'Samlet fast ejendom med ét matrikelnummer (Samvurderet) (Hvidovre)', '2024547')
        queryResult.addNewQuery(this.source, this.types.eksempler.id, '9386702 (Gyldigt BFE-nummer)', 'Samlet fast ejendom med to matrikelnumre (Samvurderet med anden ejendom)', '9386702')
        queryResult.addNewQuery(this.source, this.types.eksempler.id, '9386723 (Gyldigt BFE-nummer)', 'Samlet fast ejendom med fire matrikelnumre og syv bygninger på fremmed grund', '9386723')
        queryResult.addNewQuery(this.source, this.types.eksempler.id, '2025805 (Gyldigt BFE-nummer)', 'Samlet fast ejendom som er moderejendom for ejerlejligheder (Hvidovre)', '2025805')
        queryResult.addNewQuery(this.source, this.types.eksempler.id, '8917583 (Gyldigt BFE-nummer)', 'Samlet fast ejendom med mange bygninger', '8917583')


        queryResult.addNewQuery(this.source, this.types.eksempler.id, '225738 (Gyldigt BFE-nummer)', 'Ejerlejlighed (Hvidovre)', '225738')
        queryResult.addNewQuery(this.source, this.types.eksempler.id, '710676 (Gyldigt BFE-nummer)', 'Bygning på fremmed grund (Hvidovre)', '710676')

        caller.fetchSuccess(queryResult)
        return
      } else if (query.isBlank) {
        queryResult.addNewQuery(this.source, this.types.eksempler.id, this.types.eksempler.plural, null, '')
        caller.fetchSuccess(queryResult)
        return
      }
    }

    let bfeError = ""
    if (query.queryString.length > 5) {
      if (query.queryString.match(/\d\d\d\d\d+/) !== null) {
        try {
          let strippedQuery = query.queryString.match(/\d+/)[0]
          if (strippedQuery.length > 5) {
            let bfeResponse = await this.fetcher.read("matrikel", "matrikel", "bestemtFastEjendom", { BFEnr: strippedQuery })
            if (bfeResponse)
              queryResult = await this.bfeResponseToQueryResult(bfeResponse, queryResult)
          }
        } catch (error) {
          bfeError = error.message
        }
      }
    }
    //https://data-api.test.septima.dk/postgrest/esrejendom_bfeejendom?token=jcI0o7C9O26utwCv&kommunekode=eq.0101&ejendomsnummer=eq.530353
    try {
      let queryString = query.queryString.trim()
      let separator
      if (queryString.indexOf('-') > -1)
        separator = '-'
      if (queryString.indexOf(' ') > -1)
        separator = ' '
      let queryKommunekode
      let queryEjendomsNummer
      let dataApiBfesPromises = []
      let titleExtension = ''
      if (separator) {
        let esrParts = queryString.split(separator)
        if (esrParts.length === 2 && esrParts[0].match(/\d\d\d?\d/) && esrParts[1].match(/\d+/)) {
          queryKommunekode = esrParts[0].length === 3 ? "0" + esrParts[0] : esrParts[0]
          queryEjendomsNummer = esrParts[1]
          dataApiBfesPromises.push(this.dataapifetcher.get("esrejendom_bfeejendom", { kommunekode: "eq." + queryKommunekode, ejendomsnummer: "eq." + queryEjendomsNummer }).catch((error) => {
            throw error
          }))
          titleExtension = " (esr: " + queryKommunekode + "-" + queryEjendomsNummer + ")"
        }
      } else {
        if (this.kommunekoder.length > 0 && queryString.match(/\d+/)) {
          queryEjendomsNummer = queryString
          for (let kommunekode of this.kommunekoder) {
            queryKommunekode = kommunekode.length === 3 ? "0" + kommunekode : kommunekode
            dataApiBfesPromises.push(this.dataapifetcher.get("esrejendom_bfeejendom", { kommunekode: "eq." + queryKommunekode, ejendomsnummer: "eq." + queryEjendomsNummer }).catch((error) => {
              throw error
            }))
          }
          if (this.kommunekoder.length === 1)
            titleExtension = " (esr: " + this.kommunekoder[0] + "-" + queryEjendomsNummer + ")"
          else
            titleExtension = " (ejendomsnummer: " + queryEjendomsNummer + ")"
        }
      }
      if (dataApiBfesPromises.length > 0) {
        await Promise.allSettled(dataApiBfesPromises)
        for (let dataApiBfesPromise of dataApiBfesPromises) {
          try {
            let dataApiBfes = await dataApiBfesPromise
            if (dataApiBfes.length > 0) {
              let queryResultFromEsr = this.createQueryResult()
              let bfes = dataApiBfes.map(dataApiBfe => dataApiBfe.bfenummer)
              let bfeResponse = await this.getBfeResponse(bfes)
              if (bfeResponse) {
                queryResultFromEsr = await this.bfeResponseToQueryResult(bfeResponse, queryResultFromEsr)
                queryResultFromEsr.getResults().forEach(result => {
                  result.title = result.title + titleExtension
                  queryResult.addResult(result)
                })
              }
            }
          } catch (e) { /* empty */ }
        }

      }
    } catch (error) {
      bfeError = error.message
    }

    if (bfeError !== "") {
      let error = ""
      error = "Fejl ved kald af DAF/Matrikel: " + bfeError
      caller.fetchError(error)
    } else {
      caller.fetchSuccess(queryResult)
    }
  }

  async getBfeResponse(bfes) {
    let bfeResponse = {
      SamletFastEjendom: { features: [] },
      Ejerlejlighed: { features: [] },
      BygningPaaFremmedGrund: {
        BygningPaaFremmedGrundFlade: { features: [] },
        BygningPaaFremmedGrundPunkt: { features: [] }
      }
    }

    let promises = []
    for (let bfe of bfes)
      promises.push(this.fetcher.read("matrikel", "matrikel", "bestemtFastEjendom", { BFEnr: bfe }))
    await Promise.all(promises)
    for (let promise of promises) {
      let thisResponse = await promise
      bfeResponse.SamletFastEjendom.features.push(...thisResponse.SamletFastEjendom.features)
      bfeResponse.Ejerlejlighed.features.push(...thisResponse.Ejerlejlighed.features)
      bfeResponse.BygningPaaFremmedGrund.BygningPaaFremmedGrundFlade.features.push(...thisResponse.BygningPaaFremmedGrund.BygningPaaFremmedGrundFlade.features)
      bfeResponse.BygningPaaFremmedGrund.BygningPaaFremmedGrundPunkt.features.push(...thisResponse.BygningPaaFremmedGrund.BygningPaaFremmedGrundPunkt.features)
    }
    return bfeResponse
  }

  async bfeResponseToQueryResult(bfeResponse, queryResult) {
    if (typeof queryResult === 'undefined')
      queryResult = this.createQueryResult()
    let promises = []
    for (let sfeFeature of bfeResponse.SamletFastEjendom.features)
      promises.push(this.sfeToQueryResult(sfeFeature, queryResult))
    for (let ejlFeature of bfeResponse.Ejerlejlighed.features)
      promises.push(this.ejlToQueryResult(ejlFeature, queryResult))
    for (let bfgFeature of bfeResponse.BygningPaaFremmedGrund.BygningPaaFremmedGrundFlade.features)
      promises.push(this.bfgToQueryResult(bfgFeature, queryResult))
    for (let bfgFeature of bfeResponse.BygningPaaFremmedGrund.BygningPaaFremmedGrundPunkt.features)
      promises.push(this.bfgToQueryResult(bfgFeature, queryResult))
    await Promise.all(promises)

    return queryResult
  }

  async createSamvurderingFromVurderingsResponse(vurderingsResponse) {
    if (vurderingsResponse) {

      let count = vurderingsResponse.BFEnummerList.length
      if (count > 1) {
        let title = this.types.samvurdering.singular + ", " + count + " ejendomme"
        title = title + " (" + vurderingsResponse.år + ")"
        let geometries = []
        let dataApiResults = await this.dataapifetcher.get("bestemtfastejendom", { "bfenummer": "in.(" + vurderingsResponse.BFEnummerList.join(",") + ")" })
        for (let dataApiResult of dataApiResults)
          geometries.push(dataApiResult.geometri)
        let geometryCollection = null
        if (geometries.length > 0)
          geometryCollection = this.mergePolygonGeometries(geometries)
        let queryResult = this.createQueryResult()
        let result = queryResult.addResult(this.source, this.types.samvurdering.id, title, null, geometryCollection)
        result.id = vurderingsResponse.Vurderingsejendom.VURejendomsid
        result.data = { vurderingsResponse: vurderingsResponse }
        return result
      } else {
        return null
      }

    }
  }

  async bfgToQueryResult(bfg, queryResult) {
    if (typeof queryResult === 'undefined')
      queryResult = this.createQueryResult()
    let result
    let bfeNummer = bfg.properties.BFEnummer
    let cachedResult = this.cache.get(bfeNummer)
    if (cachedResult) {
      result = queryResult.addResult(cachedResult.source, cachedResult.typeId, cachedResult.title, cachedResult.description, cachedResult.geometry)
      result.id = bfeNummer
      result.data = cachedResult.data
    } else {
      let ebrResponse
      if (bfg.ebrResponse)
        ebrResponse = bfg.ebrResponse
      else
        ebrResponse = await this.fetcher.read("ebr", "Ejendomsbeliggenhed", "Ejendomsbeliggenhed", { BFEnr: bfeNummer }, this.getLogger())
      
      let adgangsadressebetegnelse = ""

      let geometry = bfg.properties.geometri ? this.wktParser.parse(bfg.properties.geometri, "25832") : null

      if (ebrResponse.features[0].properties.husnummer.length > 0) {
        adgangsadressebetegnelse = ", " + ebrResponse.features[0].properties.husnummer[0].adgangsadressebetegnelse
        if (geometry === 'undefined') {
          let husnummerId = ebrResponse.features[0].properties.husnummer[0].id_lokalId
          let darReply = await this.fetcher.read("dar", "dar", "Husnummer", { id: husnummerId }, this.getLogger())
          if (darReply && darReply.length > 0 && darReply[0].adgangspunkt && darReply[0].adgangspunkt.position)
            geometry = this.wktParser.parse(darReply[0].adgangspunkt.position, "25832")
        }
      }

      result = queryResult.addResult(this.source, this.types.bfg.id, "BFE " + bfeNummer, this.types.bfg.singular + adgangsadressebetegnelse, geometry)
      result.id = bfeNummer
      result.data = { ebrResponse, bfeResponse: bfg }

      this.cache.set(bfeNummer, {
        id: bfeNummer,
        source: this.source,
        typeId: this.types.bfg.id,
        title: "BFE " + bfeNummer,
        description: this.types.bfg.singular + adgangsadressebetegnelse,
        geometry: geometry,
        data: { ebrResponse, bfeResponse: bfg }
      })

    }
    return queryResult
  }

  async ejlToQueryResult(ejl, queryResult) {
    if (typeof queryResult === 'undefined')
      queryResult = this.createQueryResult()
    let result
    let bfeNummer = ejl.properties.BFEnummer

    let cachedResult = this.cache.get(bfeNummer)
    if (cachedResult) {
      result = queryResult.addResult(cachedResult.source, cachedResult.typeId, cachedResult.title, cachedResult.description, cachedResult.geometry)
      result.id = bfeNummer
      result.data = cachedResult.data
    } else {
      let samletAreal = ejl.properties.samletAreal
      let ejerlejlighedsNummer = ejl.properties.ejerlejlighedsnummer

      //Find adresse og geometri    
      let ebrResponse
      if (ejl.ebrResponse)
        ebrResponse = ejl.ebrResponse
      else
        ebrResponse = await this.fetcher.read("ebr", "Ejendomsbeliggenhed", "Ejendomsbeliggenhed", { BFEnr: bfeNummer }, this.getLogger())
      let adresse = ""
      let geometries = []
      for (let g of ejl.properties.ejerlejlighedslod) {
        if (g.geometri)
          geometries.push(this.wktParser.parse(g.geometri, "25832"))
      } 
      let mergedGeometry = this.mergePolygonGeometries(geometries)    
      
      let geometry = mergedGeometry ? mergedGeometry : null
      if (geometry === null) {
        if (ebrResponse.features[0].properties.adresse.length > 0) {
          adresse = ebrResponse.features[0].properties.adresse[0].adressebetegnelse
          let ebrWkt = ebrResponse.features[0].properties.adresse[0].husnummer.adgangspunkt.position
          geometry = this.wktParser.parse(ebrWkt, "25832")
        }
      }
      //adresse
      let titel = "BFE " + bfeNummer
      if (adresse)
        titel += " - " + adresse

      result = queryResult.addResult(this.source, this.types.ejl.id, titel, this.types.ejl.singular + " nr. " + ejerlejlighedsNummer + ", " + adresse + " - " + samletAreal + "m2", geometry)
      result.id = bfeNummer
      result.data = { ebrResponse, bfeResponse: ejl }

      this.cache.set(bfeNummer, {
        id: bfeNummer,
        source: this.source,
        typeId: this.types.ejl.id,
        title: titel,
        description: this.types.ejl.singular + " nr. " + ejerlejlighedsNummer + ", " + adresse + " - " + samletAreal + "m2",
        geometry: geometry,
        data: { ebrResponse, bfeResponse: ejl }
      })

    }

    return queryResult
  }

  async sfeToQueryResult(sfe, queryResult) {
    if (typeof queryResult === 'undefined')
      queryResult = this.createQueryResult()
    let result
    let bfeNummer = sfe.properties.BFEnummer

    let cachedResult = this.cache.get(bfeNummer)
    if (cachedResult) {
      result = queryResult.addResult(cachedResult.source, cachedResult.typeId, cachedResult.title, cachedResult.description, cachedResult.geometry)
      result.id = bfeNummer
      result.data = cachedResult.data

    } else {
      //Bestem adgangsadressebetegnelse (Del af description)
      let ebrResponse
      if (sfe.ebrResponse)
        ebrResponse = sfe.ebrResponse
      else
        ebrResponse = await this.fetcher.read("ebr", "Ejendomsbeliggenhed", "Ejendomsbeliggenhed", { BFEnr: bfeNummer }, this.getLogger())
      
      let dataApiBeliggenhedPromise = this.dataapifetcher.get("bestemtfastejendom", { "bfenummer": "eq." + bfeNummer, "select": "beliggenhed" })

      //Bestem matrikelnumre (Del af description), samt matrikelnumre-geometri
      let geometryCollection = null
      let matNrText = ""
      let ejerlavnavn = ""
      if (sfe.properties.jordstykke.length > 0 && this.geoSearcher !== null) {

        let antalJordstykker = 0
        let geometries = []
        let matIds = []
        for (let jordstykke of sfe.properties.jordstykke) {
          if (jordstykke.properties.status === "Gældende") {
            antalJordstykker += 1
            if (matNrText === "") {
              matNrText = "Matr.nr. " + jordstykke.properties.matrikelnummer
              ejerlavnavn = jordstykke.properties.ejerlavsnavn
            }
            let matId = jordstykke.properties.ejerlavskode + "-" + jordstykke.properties.matrikelnummer
            matIds.push(matId)
          }
        }

        let dataApiGeometries = await this.dataapifetcher.get("jordstykke", { "bfenummer": "eq." + bfeNummer, "select": "geometri" })
        for (let dataApiGeometry of dataApiGeometries)
          geometries.push(dataApiGeometry.geometri)

        geometryCollection = this.mergePolygonGeometries(geometries)
        if (antalJordstykker > 1)
          matNrText += " m.fl." + " (" + antalJordstykker + " jordstykker)"
      }

      let description
      let adgangsadressebetegnelse = ""


      let dataApiBeliggenhed = await dataApiBeliggenhedPromise
      if (dataApiBeliggenhed.length > 0 && dataApiBeliggenhed[0].beliggenhed)
        adgangsadressebetegnelse = dataApiBeliggenhed[0].beliggenhed

      if (adgangsadressebetegnelse !== "")
        description = adgangsadressebetegnelse + ", " + matNrText
      else
        description = matNrText + ", " + ejerlavnavn

      if (sfe.properties.ejerlejlighed.length > 0)
        description = description + " (" + sfe.properties.ejerlejlighed.length + " ejerlejligheder)"

      result = queryResult.addResult(this.source, this.types.sfe.id, "BFE " + bfeNummer, description, geometryCollection)

      result.id = bfeNummer
      //let ebrResponse = await ebrResponsePromise
      //Fjern andre bfg'er end gældende
      let bygningPaaFremmedGrund = sfe.properties.bygningPaaFremmedGrund.filter((bfg)=>bfg.status =="Gældende")
      sfe.properties.bygningPaaFremmedGrund = bygningPaaFremmedGrund
      result.data = { ebrResponse, bfeResponse: sfe }

      this.cache.set(bfeNummer, {
        id: bfeNummer,
        source: this.source,
        typeId: this.types.sfe.id,
        title: "BFE " + bfeNummer,
        description: description,
        geometry: geometryCollection,
        data: { ebrResponse, bfeResponse: sfe }
      })

    }

    return queryResult
  }

  mergePolygonGeometries(polygonGeometries) {
    //Any need to merge?
    if (polygonGeometries.length === 1)
      return polygonGeometries[0]
    let multiPolygonGeometry = {
      "type": "MultiPolygon",
      "coordinates": []
    }
    for (let geometry of polygonGeometries) {
      if (geometry !== null) {
        if (geometry.type === "Polygon")
          multiPolygonGeometry.coordinates.push(geometry.coordinates)
        else if (geometry.type === "MultiPolygon")
          multiPolygonGeometry.coordinates = [...multiPolygonGeometry.coordinates, ...geometry.coordinates]
      }
    }
    if (multiPolygonGeometry.coordinates.length > 0) {
      if (polygonGeometries[0].crs)
        multiPolygonGeometry.crs = polygonGeometries[0].crs
      return multiPolygonGeometry
    } else {
      return null
    }
  }
  santizeXml(xml) {
    xml = xml.replace('http://www.opengis.net/gml/3.2', 'http://www.opengis.net/gml')
    xml = xml.replace('xmlns:wfs="http://www.opengis.net/wfs/2.0"', 'xmlns:wfs="http://www.opengis.net/gml"')
    xml = xml.split('wfs:member').join('wfs:featureMember')
    xml = xml.replace(/(\d),0.0/g, '$1') // Remove z-values from coordinates
    xml = xml.replace(/(\d),(\d)/g, '$1 $2') // Remove , from coordinates
    xml = xml.replace(/<gml:coordinates/g, '<gml:posList srsDimension="2"')
    xml = xml.replace(/<\/gml:coordinates/g, '</gml:posList')
    return xml
  }

  removeZ(coordinates) {
    if (coordinates.length > 0 && Array.isArray(coordinates[0])) {
      for (let i = 0; i < coordinates.length; i++)
        this.removeZ(coordinates[i])
    } else {
      coordinates.length = 2
    }
  }

  async sqTypes() {
    return [this.types.sfe, this.types.bfg]
  }

  async sq(query) {
    const queryResult = this.createQueryResult()
    if (query.geometry) {
      let queryGeometry = reproject(query.geometry, null, "EPSG:25832")
      let olGeometry = new GeoJSON().readGeometry(queryGeometry)

      const featureCollection = await this.queryByGeometry(olGeometry, 'SamletFastEjendom_Gaeldende')

      let bfeNumre = []
      for (let feature of featureCollection.features)
        bfeNumre.push(feature.properties.BFEnummer)

      let results = await this.gets(bfeNumre, 'bfe')

      queryResult.addResults(results)
    }
    return queryResult
  }

  async queryByGeometry(olGeometry) {
    const olFilter = intersectsFilter('geometri', olGeometry)
    const geojson = await this.queryWithFilter(olFilter)
    return geojson
  }

  async queryWithFilter(filter) {
    let wfstypenames = ['SamletFastEjendom_Gaeldende,BygningPaaFremmedGrundFlade_Gaeldende,BygningPaaFremmedGrundPunkt_Gaeldende,Ejerlejlighed_Gaeldende']
    const params = Object.keys(this.authParamsDatafordeler).map(key => key + '=' + this.authParamsDatafordeler[key]).join('&')
    let featureNS = 'http://data.gov.dk/Matriklen/SamletFastEjendom'

    const featureRequest = new WFS().writeGetFeature({
      srsName: 'EPSG:25832',
      featureNS,
      featurePrefix: null,
      featureTypes: wfstypenames,
      //propertyNames:['BFEnummer'],
      outputFormat: 'xml',
      filter
    })
    
    let req = new XMLSerializer().serializeToString(featureRequest)
    let url = `https://services.datafordeler.dk/MATRIKLEN2/MatGaeldendeOgForeloebigWFS/1.0.0/Wfs?${params}&SERVICE=WFS&REQUEST=GetFeature`
    let xml = await this.fetch(url, {
      method: 'post',
      expects: 'xml',

      body: req
    })
    xml = this.santizeXml(xml)

    //console.log(url)
    //console.log(new XMLSerializer().serializeToString(featureRequest))

    const format = new GML3()
    const features = format.readFeatures(xml)
    const text = new GeoJSON().writeFeatures(features)
    const geojson = JSON.parse(text)
    geojson.features.forEach(f => this.removeZ(f.geometry.coordinates))
    return geojson
  }

  async get(id, type) {
    if (type === "samvurdering") {
      const sorter = function (h1, h2) {
        try {
          if (h1.år === h2.år)
            return (0)
          else
            return (h2.år - h1.år)
        } catch (error) {
          return 0
        }
      }
      //TODO: FIX
      //https://data-api.test.septima.dk/postgrest/seneste_vurdering?token=jcI0o7C9O26utwCv&vurderingsejendomid=eq.655005720001336&select=bfenummer
      //Tag det ene bfe og hent vurderingsResponse
      let bfes = await this.dataapifetcher.get("seneste_vurdering", { vurderingsejendomid: "eq." + id, select: "bfenummer" })
      if (bfes && bfes.length > 0) {
        let vurderingsResponse = await this.fetcher.read("ejendomsvurdering", "ejendomsvurdering", "HentEjendomsvurderingerForBFE", { "BFEnummer": bfes[0].bfenummer })
        vurderingsResponse.sort(sorter)
        return this.createSamvurderingFromVurderingsResponse(vurderingsResponse[0])
      }
    } else {
      let results = await this.gets([id], type)
      return results[0]
    }
  }

  async gets(idArray, type) {
    const sorter = function (b1, b2) {
      try {
        if (b1.id === b2.id)
          return (0)
        else
          return (b1.id - b2.id)
      } catch (error) {
        return 0
      }
    }
    let queryResult = this.createQueryResult()
    switch (type) {
      case "bfenummer": {
        for (let id of idArray) {
          let result = queryResult.addResult(this.source, "bfenummer", id, "bfeNummer", null, null)
          result.id = id
        }
        let results = queryResult.getResults().sort(sorter)
        return results
      }
      case "bfe":
      case "sfe":
      case "ejl":
      case "bfg": {
        let nonCachedIdArray = []
        for (let id of idArray) {
          let cachedResult = this.cache.get(id)
          if (cachedResult) {
            let result = queryResult.addResult(cachedResult.source, cachedResult.typeId, cachedResult.title, cachedResult.description, cachedResult.geometry)
            result.id = id
            result.data = cachedResult.data
          } else {
            nonCachedIdArray.push(id)
          }
        }
        if (nonCachedIdArray.length > 0) {
          let promises = []
          let bfeResponsePromise =  this.fetcher.read("matrikel", "matrikel", "bestemtFastEjendom", { BFEnr: nonCachedIdArray.join("|") })
          let ebrResponsePromise =  this.fetcher.read("ebr", "Ejendomsbeliggenhed", "Ejendomsbeliggenhed", { BFEnr: nonCachedIdArray.join("|"), pagesize: nonCachedIdArray.length }, this.getLogger())
          Promise.all([bfeResponsePromise, ebrResponsePromise])
          let bfeResponse = await bfeResponsePromise
          let ebrResponse = await ebrResponsePromise
          for (let feature of bfeResponse.SamletFastEjendom.features) {
            feature.ebrResponse = this.findEbrResponse(ebrResponse, feature.properties.BFEnummer)
            promises.push(this.sfeToQueryResult(feature, queryResult))
          }
          for (let feature of bfeResponse.Ejerlejlighed.features) {
            feature.ebrResponse = this.findEbrResponse(ebrResponse, feature.properties.BFEnummer)
            promises.push(this.ejlToQueryResult(feature, queryResult))
          }
          for (let feature of bfeResponse.BygningPaaFremmedGrund.BygningPaaFremmedGrundFlade.features) {
            feature.ebrResponse = this.findEbrResponse(ebrResponse, feature.properties.BFEnummer)
            promises.push(this.bfgToQueryResult(feature, queryResult))
          }
          for (let feature of bfeResponse.BygningPaaFremmedGrund.BygningPaaFremmedGrundPunkt.features) {
            feature.ebrResponse = this.findEbrResponse(ebrResponse, feature.properties.BFEnummer)
            promises.push(this.bfgToQueryResult(feature, queryResult))
          }
          await Promise.all(promises)
        }
        let results = queryResult.getResults().sort(sorter)
        return results
      }
    }
    return []
  }

  findEbrResponse(ebrResponse, BFEnummer) {
    for (let feature of ebrResponse.features) {
      if (feature.properties.bestemtFastEjendomBFENr === BFEnummer)
        return {features: [feature]}
    }
  }
}
