import DetailItemsRenderer from "./DetailItemsRenderer.js";

export default class DetailsLayout {
  constructor(options = {}) {
    if (options.detailItemsRenderer)
      this.detailItemsRenderer = options.detailItemsRenderer
    else
      this.detailItemsRenderer = new DetailItemsRenderer()
  }
  
  setResultActionListeners(listeners) {
    this.resultActionListeners = listeners
    if (this.detailItemsRenderer.setResultActionListeners)
      this.detailItemsRenderer.setResultActionListeners(listeners)
  }

  setDetailActionListeners(listeners) {
    this.detailActionListeners = listeners
    if (this.detailItemsRenderer.setDetailActionListeners)
      this.detailItemsRenderer.setDetailActionListeners(listeners)
  }

  render(result, filter){
    throw "DetailsLayout.render not implemented"
  }
  
  clear(){
    throw "DetailsLayout.clear not implemented"
  }

}