import { fetch2 } from '../utils.js'

export default class RouteCalculator {
	
  constructor(options) {
		
    if (options.apikey) 
      this.apikey = options.apikey
    else
      throw 'Septima.Search.RouteCalculator: option.apikey is missing'
		
    this.profile = 'car'
    if (options.profile) 
      //    		'car'
      //    		'bus'
      //    		'bicycle'
      //    		'foot'
      this.profile = options.profile
		
    if (options.fromGeometry) 
      this.fromGeometry = options.fromGeometry
    else
      throw 'Septima.Search.RouteCalculator: options.fromGeometry is missing'
		
    if (options.toGeometry) {
      this.toGeometry = options.toGeometry
    } else {
      throw 'Septima.Search.RouteCalculator: option.toFeatureCollection/toGeometry is missing'
    }
  }
	
  async calculate() {
    return await this._calculatePointToPoint(this.fromGeometry, this.toGeometry)
  }

  async _calculatePointToPoint(fromPointGeometry, toPointGeometry) {
    const fromPoint = this.getPointFromGeometry(fromPointGeometry)
    const toPoint = this.getPointFromGeometry(toPointGeometry)
    let latlonstr = "&lat=" + toPoint[1].toFixed(6) + "&lon=" + toPoint[0].toFixed(6)

    const url = 'https://rute.k8s-test-127.septima.dk/' + this.apikey + '/one2many?version=5&engine=' + this.profile +
      '&returntype=json&fromlat=' + fromPoint[1].toFixed(6) + '&fromlon=' + fromPoint[0].toFixed(6) + latlonstr

    let data = await fetch2(url, {})

    let precision = 1e-5

    let thisData = data[0]
    let route = thisData.routes[0]
    let geometry = {
      "type" : "LineString",
      "coordinates" : this.decode(route.geometry, precision)
    } 
    return {
      time: Math.floor(route.duration),
      distance: Math.floor(route.distance),
      geometry: geometry
    } 
  }
	
  getPointFromGeometry(geometry) {
    if (geometry.type === 'Point') 
      return geometry.coordinates
    else if (geometry.type === 'MultiPoint' || geometry.type === 'LineString')
      return geometry.coordinates[0]
    else
      return geometry.coordinates[0][0]
  }

  decode(encoded, precision) {
    const len = encoded.length
    let index = 0
    const array = []
    let lat = 0
    let lng = 0

    while (index < len) {
      let b
      let shift = 0
      let result = 0
      do {
        b = encoded.charCodeAt(index++) - 63
        result |= (b & 0x1f) << shift
        shift += 5
      } while (b >= 0x20)
      //noinspection UnnecessaryLocalVariableJS,JSBitwiseOperatorUsage
      const dlat = ((result & 1) ? ~(result >> 1) : (result >> 1))
      lat += dlat

      shift = 0
      result = 0
      do {
        b = encoded.charCodeAt(index++) - 63
        result |= (b & 0x1f) << shift
        shift += 5
      } while (b >= 0x20)
      //noinspection JSBitwiseOperatorUsage,UnnecessaryLocalVariableJS
      const dlng = ((result & 1) ? ~(result >> 1) : (result >> 1))
      lng += dlng

      array.push([lng * precision, lat * precision])
    }

    return array
  }
}
