/**
 * @module
 */
import ResultType from "../ResultType.js"
import Searcher from './Searcher.js'
import * as reproject from "../util/reproject.js"
import { getWKTParser } from "../util/getWKTParser.js"
import icons from '../resources/icons.js'
import DataApiFetcher from "./data-api/Fetcher.js"
/**
 *
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
  _type: Septima.Search.PlanSystemSearcher
  _options:
    minimumShowCount: 3
    searchindexToken: TOKEN
    targets:
      - vedtagetlokalplan
      - kommuneplanramme
      - regionplan
      - byggefelt
      - delområde

 * @example <caption> JS options: </caption>
 options = {
    searchindexToken: 'TOKEN'
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.PlanSystemSearcher(options))
 *
 * @example <caption>ES6:</caption>
 * import PlanSystemSearcher from './searchers/PlanSystemSearcher.js'
 * controller.addSearcher(new PlanSystemSearcher(options))
 */


export default class PlanSystemSearcher extends Searcher {
  /**
   *
   * @param {Object} options PlanSystemSearcher expects these properties:
   * @param {string} options.searchindexToken Please contact Septima to obtain a valid token
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param {string} [options.targets=['kommuneplanramme','vedtagetlokalplan']]  Muligheder: vedtagetlokalplan, lokalplanforslag, kommuneplanramme, kommuneplantillaeg, regionplan, byggefelt, delområde, vedtagetkloakopland, mulighedforudtraedenkloakopland, zonestatus, kystnaerhedszone, landzonetilladelse
   * @api
   */
  
  registerType(source, type) {
    super.registerType(source, type)
    if (this.targets.indexOf(type.id) > - 1)
      this.targetTypes.push(type)
  }
  
  constructor(options) {
    super(options)
    this.source = "plansystem"
    if (options.source)
      this.source = options.source

    this.targets = ['kommuneplanramme', 'vedtagetlokalplan']
    this.targetTypes  = []
    if (typeof options !== 'undefined' && typeof options.targets !== 'undefined')
      this.targets = options.targets
    this.pdkEndpoint = "https://geoserver.plandata.dk/geoserver/wfs"
    this.fingerplanEndpoint = "https://wfs1.erst.dk/fingerplan19/wfs"
    this.types = {
      "lp": new ResultType({ id: "vedtagetlokalplan", singular: "Vedtaget lokalplan", plural: "Vedtagne lokalplaner", endpoint: this.pdkEndpoint, featuretype: "theme_pdk_lokalplan_vedtaget_v", iconURI: icons.document }),
      "lpf": new ResultType({ id: "lokalplanforslag", singular: "Lokalplanforslag", plural: "Lokalplanforslag", queryBehaviour: "match", endpoint: this.pdkEndpoint, featuretype: "theme_pdk_lokalplan_forslag_v", iconURI: icons.document }),
      "kpl": new ResultType({ id: "kommuneplanramme", singular: "Kommuneplanramme", plural: "Kommuneplanrammer", queryBehaviour: "match", endpoint: this.pdkEndpoint, featuretype: "theme_pdk_kommuneplanramme_alle_vedtaget_v", iconURI: icons.document }),
      "kpt": new ResultType({ id: "kommuneplantillaeg", singular: "Kommuneplantillæg", plural: "Kommuneplantillæg", queryBehaviour: "match", endpoint: this.pdkEndpoint, featuretype: "theme_pdk_kommuneplantillaeg_vedtaget_v", iconURI: icons.document }),
      "rp": new ResultType({ id: "regionplan", singular: "Regionplan", plural: "Regionplaner", queryBehaviour: "match", endpoint: this.pdkEndpoint,  featuretype: "regionplaner", iconURI: icons.document }),
      "bf": new ResultType({ id: "byggefelt", singular: "Byggefelt", plural: "Byggefelter", queryBehaviour: "match", endpoint: this.pdkEndpoint, featuretype: "theme_pdk_byggefelt_vedtaget_v", iconURI: icons.document }),
      "do": new ResultType({ id: "delområde", singular: "Delområde", plural: "Delområde", queryBehaviour: "match", endpoint: this.pdkEndpoint, featuretype: "theme_pdk_lokalplandelomraade_vedtaget_v", iconURI: icons.document }),
      "ko": new ResultType({ id: "vedtagetkloakopland", singular: "Kloakopland", plural: "Kloakoplande", queryBehaviour: "match", endpoint: this.pdkEndpoint, featuretype: "theme_pdk_kloakopland_vedtaget_v", iconURI: icons.document }),
      "kou": new ResultType({ id: "mulighedforudtraedenkloakopland", singular: "Kloakopland (med mulighed for udtræden)", plural: "Kloakoplande (med mulighed for udtræden)", queryBehaviour: "match", endpoint: this.pdkEndpoint, featuretype: "theme_pdk_mulighedforudtraedenkloakopland_vedtaget_v", iconURI: icons.document }),
      "zs": new ResultType({ id: "zonestatus", singular: "Zonestatus", plural: "Zonestatus", queryBehaviour: "match", endpoint: this.pdkEndpoint, featuretype: "theme_pdk_zonekort_v", iconURI: icons.document }),     
      "knz": new ResultType({ id: "kystnaerhedszone", singular: "Kystnærhedszone", plural: "Kystnærhedszone", queryBehaviour: "match",endpoint: this.pdkEndpoint, featuretype: "theme-knz-kystnaerhedszone-polygon", iconURI: icons.document }),
      "tpk": new ResultType({ id: "transportkorridor", singular: "Transportkorridor", plural: "Transportkorridorer", queryBehaviour: "match", endpoint: this.fingerplanEndpoint, featuretype: "fp19vn_transportkorridorer", iconURI: icons.document }),
      "lzt": new ResultType({ id: "landzonetilladelse", singular: "Landzonetilladelse", plural: "Landzonetilladelseer", queryBehaviour: "match", endpoint: this.pdkEndpoint, featuretype: "theme_pdk_landzonetilladelse_vedtaget_v", iconURI: icons.document })
    }
    this.registerType(this.source, this.types.lp)
    this.registerType(this.source, this.types.lpf)
    this.registerType(this.source, this.types.kpl)
    this.registerType(this.source, this.types.kpt)
    this.registerType(this.source, this.types.rp)
    this.registerType(this.source, this.types.bf)
    this.registerType(this.source, this.types.do)
    this.registerType(this.source, this.types.ko)
    this.registerType(this.source, this.types.kou)
    this.registerType(this.source, this.types.zs)
    this.registerType(this.source, this.types.knz)
    this.registerType(this.source, this.types.tpk)
    this.registerType(this.source, this.types.lzt)

    if (this.targets.indexOf('vedtagetlokalplan') > -1) {
      if (options.dataapifetcher) {
        this.dataapifetcher = options.dataapifetcher
      } else {
        if (options.dataapiToken)
          this.dataapifetcher = new DataApiFetcher({token: options.dataapiToken})
        else
          this.dataapifetcher = new DataApiFetcher()
      }

      if (options.kommunekode && options.kommunekode !== "*") {
        this.kommunekode = "" + options.kommunekode
        this.lpSearchKommunekodeParams = this.kommunekode.split(' ')
      }
    }
    
    reproject.registerCrs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      let gotResult = await this.get(result.id, result.typeId)
      result.geometry = gotResult.geometry
      result.geometry.crs = {
        "type": "name",
        "properties": {
          "name": "epsg:25832"
        }
      }
      result.data = gotResult.data
      result.isComplete = true
      return result
    }
  }

  async fetchData(query, caller) {
    const queryResult = this.createQueryResult()

    let lpFetchDataPromise = Promise.resolve(this.createQueryResult())
    if (this.dataapifetcher && (!query.hasTarget || query.target.type === this.types.lp.id) && this.targets.indexOf('vedtagetlokalplan') > -1)
      lpFetchDataPromise = this.lp_searchLokalplaner(query).catch((error) => {
        throw error
      })

    let fetchPromises = []
    if (query.queryString !== "" ) {
      if (!query.hasTarget) {
        if (!isNaN(parseInt(query.queryString))) {
          if (parseInt(query.queryString) && this.targets.indexOf('kommuneplanramme') > -1)
            fetchPromises.push(this.fetchKommuneplanRammeById(query.queryString).catch((error) => {
              throw error
            }))
          if (parseInt(query.queryString) && this.targets.indexOf('kommuneplantillaeg') > -1)
            fetchPromises.push(this.fetchKommuneplanTillaegById(query.queryString).catch((error) => {
              throw error
            }))
          if (parseInt(query.queryString) && this.targets.indexOf('lokalplanforslag') > -1)
            fetchPromises.push(this.fetchLokalplanforslagById(query.queryString).catch((error) => {
              throw error
            }))
        }
      }
    }

    //Process fetch promises
    
    for (let fetchPromise of await Promise.allSettled(fetchPromises)) {
      if (fetchPromise.status == "fulfilled") {
        let features = fetchPromise.value
        this.addFeaturesToQueryResult(features, queryResult)
      }
    }

    /*
    let featuresArray = await Promise.all(fetchPromises)
    for (let features of featuresArray)
      this.addFeaturesToQueryResult(features, queryResult)
     */

    //Process lpFetchDataPromise
    let lpQueryResult = await lpFetchDataPromise
    for (let lpResult of lpQueryResult.getResults()) {
      let title = `${this.fixString(lpResult.title)}`
      let result = queryResult.addResult(this.source, this.types.lp.id, title, lpResult.description)
      result.id = lpResult.id
      result.isComplete = false
    }
    let plural = this.getType(this.source, this.types.lp.id).plural
    for (let newQuery of lpQueryResult.getNewQueries())
      queryResult.addNewQuery(this.source, this.types.lp.id, plural, newQuery.description, newQuery.newquery)

    caller.fetchSuccess(queryResult)
  }

  async get(id, type) {
    let queryResult = this.createQueryResult()
    let result = this.getCacheResult(type, id)
    if (result) {
      return result
    } else {
      if (type === this.types.lp.id) {
        let features = await this.fetchVedtagetLokalplanById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.lpf.id) {
        let features = await this.fetchLokalplanforslagById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.kpl.id) {
        let features = await this.fetchKommuneplanRammeById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.kpt.id) {
        let features = await this.fetchKommuneplanTillaegById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.bf.id) {
        let features = await this.fetchByggeFeltById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.do.id) {
        let features = await this.fetchDelomraadeById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.rp.id) {
        let features = await this.fetchRegionPlanById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.ko.id) {
        let features = await this.fetchKloakoplandById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.kou.id) {
        let features = await this.fetchMulighedforudtraedenkloakoplandById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.zs.id) {
        let features = await this.fetchZonestatusById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.knz.id) {
        let features = await this.fetchKystnaerhedszoneById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.lzt.id) {
        let features = await this.fetchLandzonetilladelseById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else if (type === this.types.tpk.id) {
        let features = await this.fetchTransportKorridorById(id)
        if (features.length === 1)
          this.addFeatureToQueryResult(features[0], queryResult)
      } else {
        throw new Error('Whoops! get(id) is not implemented for ' + type)
      }

      let results = queryResult.getResults()
      if (results.length === 1)
        return results[0]
    }
  }

  fixString(stringToFix) {
    let returnString = stringToFix
      .replace('Ã¸', 'ø')
      .replace('tillg', 'tillæg')
      .replace('Ã¦', 'æ')
      .replace('Storstrom', 'Storstrøm')
      .replace('Ribe_amt_samlet', ' Ribe Amt samlet')
      .replace('Snderjyllands', 'Sønderjyllands')
      .replace('Ringkbing', 'Ringkøbing')
      .replace('Vestsjlland', 'Vestsjælland')
      .replace('Ã¸', 'ø')
      .replace('Ã¦', 'æ')
      .replace('Ã', 'Å')
      .replace('Ã¥', 'å')

    return returnString
  }

  addFeaturesToQueryResult(features, queryResult) {
    for (let feature of features)
      this.addFeatureToQueryResult(feature, queryResult)
  }

  addFeatureToQueryResult(feature, queryResult) {

    let typeId = this.featureToTypeId(feature)
    let title
    let description

    if (typeId === this.types.lp.id) {
      if (feature.properties.plannavn.startsWith(feature.properties.plannr))
        title = feature.properties
      else 
        title = `${this.fixString(feature.properties.plannr)} - ${this.fixString(feature.properties.plannavn)}` 
      if (typeof feature.properties.anvendelsegenerel == "undefined")
        //description = 'Plannummer ' + feature.properties.plannr
        description = 'Ingen anvendelse angivet'
      else
        //description = 'Plannummer ' + feature.properties.plannr + ' - ' + feature.properties.anvendelsegenerel
        description =   feature.properties.anvendelsegenerel
    } else if (typeId === this.types.lpf.id) {
      title = feature.properties.plannavn
      description = feature.properties.anvendelsegenerel
    } else if (typeId === this.types.kpl.id) {
      if (feature.properties.plannavn.startsWith(feature.properties.plannr))
        title = feature.properties.plannavn
      else
        title = `${feature.properties.plannr} - ${feature.properties.plannavn}`
      description = feature.properties.anvendelsegenerel

    } else if (typeId === this.types.kpt.id) {
      title = feature.properties.plannavn
      description = feature.properties.anvendelsegenerel
    } else if (typeId == this.types.bf.id) {
      title = `${feature.properties.delnr}`
      let anvendelsesTekst = this.getAnvendelsesTekstFraFeature(feature)
      if (this.hasvalue(anvendelsesTekst))
        description = `Byggefelt (${anvendelsesTekst}) til lokalplan ${feature.properties.lp_plannavn}`
      else
        description = `Byggefelt til lokalplan ${feature.properties.lp_plannavn}`

    } else if (typeId === this.types.do.id) {
      title = `${feature.properties.delnr}`
      let anvendelsesTekst = this.getAnvendelsesTekstFraFeature(feature)
      if (this.hasvalue(anvendelsesTekst))
        description = `Delområde (${anvendelsesTekst}) til lokalplan ${feature.properties.lp_plannavn}`
      else
        description = `Delområde til lokalplan ${feature.properties.lp_plannavn}`

    } else if (typeId === this.types.ko.id) {
      title = `Opland ${feature.properties.navn1201}`
      description = feature.properties.vaerd1201a
    } else if (typeId === this.types.kou.id) {
      title = feature.properties.objekt_id
      description = feature.properties.vaerdi1206
    } else if (typeId === this.types.rp.id) {
      title = this.fixString(feature.properties.dokumentna)
      description = this.fixString(feature.properties.amtnavn)
    } else if (typeId === this.types.zs.id) {
      title = feature.properties.zonestatus
      description = feature.properties.kommunenavn
    } else if (typeId === this.types.lzt.id) {
      title = feature.properties.lztnavn
      description = `Nr: ${feature.properties.lztnr} - ${feature.properties.matrbetegn}`
    } else if (typeId === this.types.knz.id) {
      title = feature.properties.navn
      description = feature.properties.regionsnav
    } else if (typeId === this.types.tpk.id) {
      title = feature.properties.hovedstruk
      description = feature.properties.kode
    }

    let geometry = feature.geometry
    geometry.crs = {
      "type": "name",
      "properties": {
        "name": "epsg:25832"
      }
    }
    let result = queryResult.addResult(this.source, typeId, title, description, geometry, feature)
    if (typeId === this.types.rp.id)
      result.id = feature.id
    else if (typeId === this.types.ko.id)
      result.id = feature.properties.objekt_id
    else if (typeId === this.types.kou.id)
      result.id = feature.properties.objekt_id
    else if (typeId === this.types.knz.id)
      result.id = feature.id
    else if (typeId === this.types.tpk.id)
      result.id = feature.id
    else
      result.id = feature.properties.planid

    this.setCacheResult(typeId, result)

    return result
  }

  setCacheResult(typeId, result) {
    switch (typeId) {
      case this.types.lp.id:
        this.types.lp.cache.setResult(result)
        break
      case this.types.lpf.id:
        this.types.lpf.cache.setResult(result)
        break
      case this.types.kpl.id:
        this.types.kpl.cache.setResult(result)
        break
      case this.types.kpt.id:
        this.types.kpt.cache.setResult(result)
        break
      case this.types.rp.id:
        this.types.rp.cache.setResult(result)
        break
      case this.types.bf.id:
        this.types.bf.cache.setResult(result)
        break
      case this.types.do.id:
        this.types.do.cache.setResult(result)
        break
      case this.types.ko.id:
        this.types.ko.cache.setResult(result)
        break
      case this.types.kou.id:
        this.types.kou.cache.setResult(result)
        break
      case this.types.zs.id:
        this.types.zs.cache.setResult(result)
        break
      case this.types.knz.id:
        this.types.knz.cache.setResult(result)
        break
      case this.types.lzt.id:
        this.types.lzt.cache.setResult(result)
        break
      case this.types.tpk.id:
        this.types.tpk.cache.setResult(result)
        break
    }
  }

  getCacheResult(typeId, id) {
    switch (typeId) {
      case this.types.lp.id:
        return this.types.lp.cache.get(id)
      case this.types.lpf.id:
        return this.types.lpf.cache.get(id)
      case this.types.kpl.id:
        return this.types.kpl.cache.get(id)
      case this.types.kpt.id:
        return this.types.kpt.cache.get(id)
      case this.types.rp.id:
        return this.types.rp.cache.get(id)
      case this.types.bf.id:
        return this.types.bf.cache.get(id)
      case this.types.do.id:
        return this.types.do.cache.get(id)
      case this.types.ko.id:
        return this.types.ko.cache.get(id)
      case this.types.kou.id:
        return this.types.kou.cache.get(id)
      case this.types.zs.id:
        return this.types.zs.cache.get(id)
      case this.types.knz.id:
        return this.types.knz.cache.get(id)
      case this.types.lzt.id:
        return this.types.lzt.cache.get(id)
      case this.types.tpk.id:
        return this.types.tpk.cache.get(id)
    }
  }


  featureToTypeId(feature) {
    let featureType = feature.id.split('.')[0]
    let type
    Object.entries(this.types).map(obj => {
      let thisType = obj[1]
      if (thisType.values.featuretype === featureType)
        type = thisType
    })
    return type.id
  }

  getTypenamesFromTargets(targets, types) {
    let typeNames = []
    Object.entries(types).forEach(entry => {
      let resultType = entry[1]
      if (targets.indexOf(resultType.id) > -1)
        typeNames.push(resultType.values.featuretype)
    })
    return typeNames
  }

  getTypenameFromTarget(target, types) {
    let typeName
    Object.entries(types).forEach(entry => {
      let resultType = entry[1]
      if (resultType.id === target)
        typeName = resultType.values.featuretype
    })
    return typeName
  }

  async sqTypes() {
    return this.targetTypes
  }
  
  async sq(query) {
    let queryResult = this.createQueryResult()
    if (query.geometry) {
      let queryGeometry = reproject.reproject(query.geometry, null, "EPSG:25832")
      let wktParser = getWKTParser()
      const queryWkt = wktParser.convert(queryGeometry)
      let fetchPromises = []
      let typeNames = []
      if (this.targets.indexOf('vedtagetlokalplan') > -1)
        typeNames.push(this.getTypenameFromTarget('vedtagetlokalplan', this.types))
      if (this.targets.indexOf('lokalplanforslag') > -1)
        typeNames.push(this.getTypenameFromTarget('lokalplanforslag', this.types))

      if (this.targets.indexOf('kommuneplanramme') > -1)
        typeNames.push(this.getTypenameFromTarget('kommuneplanramme', this.types))
      if (this.targets.indexOf('kommuneplantillaeg') > -1)
        typeNames.push(this.getTypenameFromTarget('kommuneplantillaeg', this.types))
      if (this.targets.indexOf('byggefelt') > -1)
        typeNames.push(this.getTypenameFromTarget('byggefelt', this.types))

      if (this.targets.indexOf('delområde') > -1)
        typeNames.push(this.getTypenameFromTarget('delområde', this.types))
      if (this.targets.indexOf('vedtagetkloakopland') > -1)
        typeNames.push(this.getTypenameFromTarget('vedtagetkloakopland', this.types))
      if (this.targets.indexOf('mulighedforudtraedenkloakopland') > -1)
        typeNames.push(this.getTypenameFromTarget('mulighedforudtraedenkloakopland', this.types))

      if (typeNames.length > 0)
        fetchPromises.push(this.fetchFeatures(typeNames.join(","), 'intersects(geometri,' + queryWkt + ')', "post"))

      if (this.targets.indexOf('regionplan') > -1)
        fetchPromises.push(this.fetchFeatures(this.getTypenameFromTarget('regionplan', this.types), 'intersects(the_geom,' + queryWkt + ')', "get"))
      if (this.targets.indexOf('zonestatus') > -1)
        fetchPromises.push(this.fetchFeatures(this.getTypenameFromTarget('zonestatus', this.types), 'intersects(geometri,' + queryWkt + ')', "get"))
      if (this.targets.indexOf('landzonetilladelse') > -1)
        fetchPromises.push(this.fetchFeatures(this.getTypenameFromTarget('landzonetilladelse', this.types), 'intersects(geometri,' + queryWkt + ')', "get"))      
      if (this.targets.indexOf('kystnaerhedszone') > -1)
        fetchPromises.push(this.fetchFeatures(this.getTypenameFromTarget('kystnaerhedszone', this.types), 'intersects(the_geom,' + queryWkt + ')', "get"))
      if (this.targets.indexOf('transportkorridor') > -1)
        fetchPromises.push(this.fetchFeatures(this.getTypenameFromTarget('transportkorridor', this.types), 'intersects(wkb_geometry,' + queryWkt + ')', "get",this.fingerplanEndpoint))
  
      //Process fetch promises
      let featuresArray = await Promise.all(fetchPromises)
      for (let features of featuresArray)
        for (let feature of features)
          this.addFeatureToQueryResult(feature, queryResult)
    }

    return queryResult
  }

  async fetchVedtagetLokalplanById(id) {
    return await this.fetchFeatures("theme_pdk_lokalplan_vedtaget_v", `planid=${id}`)
  }

  async fetchVedtagetLokalplanByNummer(plannr) {
    return await this.fetchFeatures("theme_pdk_lokalplan_vedtaget_v", `plannr='${plannr}'`)
  }

  async fetchLokalplanforslagById(id) {
    return await this.fetchFeatures("theme_pdk_lokalplan_forslag_v", `planid=${id}`)
  }

  async fetchKommuneplanRammeById(id) {
    try {
      return await this.fetchFeatures("theme_pdk_kommuneplanramme_alle_vedtaget_v", `planid=${id}`)
    } catch {
      return []
    }
  }
  async fetchKommuneplanTillaegById(id) {
    return await this.fetchFeatures("theme_pdk_kommuneplantillaeg_vedtaget_v", `planid=${id}`)
  }

  async fetchByggeFeltById(id) {
    return await this.fetchFeatures("theme_pdk_byggefelt_vedtaget_v", `planid=${id}`)
  }

  async fetchRegionPlanById(id) {
    //skal ikke refaktoreres
    let url = `https://geoserver.plandata.dk/geoserver/wfs?servicename=wfs&request=getFeature&typename=region:regionplaner&featureID=${id}&outputformat=json`
    url = encodeURI(url)
    let response = await this.fetch(url)
    if (response && response.features)
      return response.features
    else
      return []
  }
  async fetchZonestatusById(id) {
    return await this.fetchFeatures("theme_pdk_zonekort_v", `planid=${id}`)
  }
  async fetchLandzonetilladelseById(id) {
    return await this.fetchFeatures("theme_pdk_landzonetilladelse_vedtaget_v", `planid=${id}`)
  }
  async fetchKystnaerhedszoneById(id) {
    return await this.fetchFeatures("theme-knz-kystnaerhedszone-polygon", `gml_id=${id}`)
  }
  async fetchTransportKorridorById(id) {
    return await this.fetchFeatures("fp19vn_transportkorridorer", `id=${id}`, "get", this.fingerplanEndpoint )
  }
  async fetchDelomraadeById(id) {
    return await this.fetchFeatures("theme_pdk_lokalplandelomraade_vedtaget_v", `planid=${id}`)
  }
  async fetchKloakoplandById(id) {
    return await this.fetchFeatures("theme_pdk_kloakopland_vedtaget_v", `objekt_id=${id}`)
  }
  async fetchMulighedforudtraedenkloakoplandById(id) {
    return await this.fetchFeatures("theme_pdk_mulighedforudtraedenkloakopland_vedtaget_v", `objekt_id=${id}`)
  }

  async fetchFeatures(typename, cqlFilter, method = "get", endpoint = this.pdkEndpoint) {
    try {
      let endPoint = endpoint
      let result
      if (method === 'get') {
        result = await this.fetch(`${endPoint}?request=getFeature&typename=${typename}&cql_filter=${cqlFilter}&outputFormat=json`)
      } else {
        let params = {
          servicename: 'WFS',
          typename: typename,
          request: 'GetFeature',
          version: '1.0.0',
          outputformat: 'json',
          CQL_FILTER: cqlFilter
        }
        result = await this.fetch(endPoint, { data: params, method: 'post' })
      }
      return result.features
    } catch (e) {
      return []
    }
  }

  async getByggefeltByPlanId(id) {
    let queryResult = this.createQueryResult()
    try {
      let features = await this.fetchFeatures("theme_pdk_byggefelt_vedtaget_v", `lokplan_id=${id}`)
      for (let feature of features)
        this.addFeatureToQueryResult(feature, queryResult)
      return queryResult
    } catch (e) {
      return queryResult
    }
  }

  async getDelomraadeByPlanId(id) {
    let queryResult = this.createQueryResult()
    let features = await this.fetchFeatures("theme_pdk_lokalplandelomraade_vedtaget_v", `lokplan_id=${id}`)
    for (let feature of features)
      this.addFeatureToQueryResult(feature, queryResult)
    return queryResult
  }

  async getRelationsForHit(result) {
    if (result.typeId === this.types.bf.id) {
      let planResult = await this.get(result.data.properties.lokplan_id, 'vedtagetlokalplan')
      return { children: [], parents: [planResult], siblings: [] }
    }
    return { children: [], parents: [], siblings: [] }
  }

  async lp_searchLokalplaner (query) {
    if (query.type === 'collapse') {
      let plural = this.getType(this.source, this.types.lp.id).plural
      let queryResult = this.createQueryResult()
      let result = queryResult.addNewQuery(this.source, this.types.lp.id, plural, null, '', null, null, null)
      result.image = this.iconURI
      return queryResult
    }else {
      let filter = {}
      if (this.lpSearchKommunekodeParams)
        filter.kommunekoder = this.lpSearchKommunekodeParams
      let data = await this.dataapifetcher.search("lokalplan_search", query.queryString, query.limit + 5, filter)
      if (data) {
        data.query = query
        return this.lp_parseResult(data)
      } else {
        return this.createQueryResult()
      }
    }
  }

  lp_parseResult(data) {
    const queryResult = this.createQueryResult()
    const query = data.query
    const count = data.length
    let plural = this.getType(this.source, this.types.lp.id).plural
    
    let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)

    for (let hit of data.slice(0, hitsShown))
      this.lp_addResultFromHit(query, queryResult, hit)

    if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
      if (hitsShown > 0)
        queryResult.addNewQuery(this.source, this.types.lp.id, "Flere " + plural, null, query.queryString, null, null)
      else
        queryResult.addNewQuery(this.source, this.types.lp.id, plural, null, query.queryString, null, null)
    }
    return queryResult
  }

  lp_addResultFromHit(query, queryResult, hit) {
    let singular = this.  getType(this.source, this.types.lp.id).singular
    let displayName
    if (hit.plannavn.startsWith(hit.plannr))
      displayName = `${hit.plannavn}`
    else
      displayName = `${hit.plannr} - ${hit.plannavn}`
    
    if (query.type === "cut")
      displayName += " (" + singular + ")"

    //let description = "Plannr. " + hit.plannr
    let description = this.getGenerelAnvendelsesTekstFraKode(hit.anvgen)
    let resultGeometry = hit.geometry
    let result = queryResult.addResult(this.source, this.types.lp.id, displayName, description, resultGeometry, hit)
    result.id = hit.planid
    result.isComplete = false
  }


  getAnvendelsesTekstFraFeature(feature) {
    let anvspecvals = []
    Object.entries(feature.properties).map(obj => {
      const key = obj[0]
      const value = obj[1]
      if (key.startsWith('anvspec') && value)
        anvspecvals.push(this.getAnvendelsesTekstFraKode(value))
    })
    return anvspecvals.join(', ')
  }
  hasvalue(item) {
    if (typeof item !== 'undefined') {
      if (item !== '' && item !== null)
        return true
    }
    return false
  }
  getAnvendelsesTekstFraKode(kode) {
    const anvendelseskoder = {
      "1100": {
        "tekst": "Boligområde"
      },
      "1110": {
        "tekst": "Åben-lav boligbebyggelse"
      },
      "1120": {
        "tekst": "Tæt-lav boligbebyggelse"
      },
      "1130": {
        "tekst": "Etageboligbebyggelse"
      },
      "1140": {
        "tekst": "Blandet boligområde"
      },
      "1150": {
        "tekst": "Almene boliger"
      },
      "1160": {
        "tekst": "Fællesanlæg"
      },
      "2110": {
        "tekst": "Blandet byområde"
      },
      "2120": {
        "tekst": "Landsbyområde"
      },
      "2130": {
        "tekst": "Jordbrugsparceller"
      },
      "2140": {
        "tekst": "Blandet bolig og erhverv"
      },
      "3100": {
        "tekst": "Erhvervsområde"
      },
      "3110": {
        "tekst": "Kontor- og serviceerhverv"
      },
      "3115": {
        "tekst": "Industri"
      },
      "3120": {
        "tekst": "Let industri og håndværk"
      },
      "3130": {
        "tekst": "Tung industri"
      },
      "3140": {
        "tekst": "Virksomheder med særlige beliggenhedskrav"
      },
      "3150": {
        "tekst": "Havneerhvervsområde"
      },
      "3155": {
        "tekst": "Transport- og logistikvirksomheder"
      },
      "3160": {
        "tekst": "Erhvervsområde"
      },
      "3165": {
        "tekst": "Produktionsvirksomhed"
      },
      "3166": {
        "tekst": "Konsekvensområde omkring produktionsvirksomheder"
      },
      "3167": {
        "tekst": "Transformationsområde"
      },
      "3170": {
        "tekst": "Byomdannelsesområde"
      },
      "3180": {
        "tekst": "Oplevelsesøkonomisk center"
      },
      "3190": {
        "tekst": "Fødevarevirksomhed med besøgsfaciliteter"
      },
      "4110": {
        "tekst": "Bycenter"
      },
      "4115": {
        "tekst": "Bymidte"
      },
      "4120": {
        "tekst": "Bydelscenter"
      },
      "4130": {
        "tekst": "Butikker til lokalområdets forsyning"
      },
      "4135": {
        "tekst": "Butikker"
      },
      "4140": {
        "tekst": "Område til butiksformål"
      },
      "4150": {
        "tekst": "Skilte/facade regulering"
      },
      "4160": {
        "tekst": "Bygningsbevarende pladskrævende varer"
      },
      "4177": {
        "tekst": "Tankstation"
      },
      "4180": {
        "tekst": "Publikumsorienterede serviceerhverv"
      },
      "4190": {
        "tekst": "Aflastningsområde"
      },
      "5110": {
        "tekst": "Større rekreativt område"
      },
      "5111": {
        "tekst": "Koloni- og nyttehaver"
      },
      "5115": {
        "tekst": "Nærrekreativt område"
      },
      "5116": {
        "tekst": "Beplantning"
      },
      "5117": {
        "tekst": "Bevaringsværdig beplantning"
      },
      "5120": {
        "tekst": "Sports- og idrætsanlæg"
      },
      "5121": {
        "tekst": "Golfbaneanlæg"
      },
      "5122": {
        "tekst": "Støjende fritidsanlæg"
      },
      "5123": {
        "tekst": "Lystbådehavn"
      },
      "5130": {
        "tekst": "Ferie- og kongrescenter"
      },
      "5131": {
        "tekst": "Campingplads og vandrerhjem"
      },
      "5140": {
        "tekst": "Campingplads og vandrerhjem"
      },
      "5150": {
        "tekst": "Rekreativt område"
      },
      "5190": {
        "tekst": "Øvrige ferie- og fritidsformål"
      },
      "6110": {
        "tekst": "Sommerhus- og fritidsboligbebyggelse"
      },
      "7100": {
        "tekst": "Område til offentlige formål"
      },
      "7110": {
        "tekst": "Uddannelsesinstitutioner"
      },
      "7120": {
        "tekst": "Sundhedsinstitutioner"
      },
      "7130": {
        "tekst": "Sociale institutioner"
      },
      "7131": {
        "tekst": "Institutioner"
      },
      "7133": {
        "tekst": "Daginstitutioner"
      },
      "7135": {
        "tekst": "Døgninstitutioner"
      },
      "7140": {
        "tekst": "Kulturelle institutioner"
      },
      "7150": {
        "tekst": "Offentlig administration"
      },
      "7160": {
        "tekst": "Religiøse institutioner og gravpladser"
      },
      "7170": {
        "tekst": "Område til offentlige formål"
      },
      "7180": {
        "tekst": "Fængsler"
      },
      "8100": {
        "tekst": "Tekniske anlæg"
      },
      "8110": {
        "tekst": "Forsyningsanlæg"
      },
      "8111": {
        "tekst": "Vindmølleanlæg"
      },
      "8112": {
        "tekst": "Husstandsvindmølle"
      },
      "8120": {
        "tekst": "Deponeringsanlæg"
      },
      "8130": {
        "tekst": "Rensningsanlæg"
      },
      "8133": {
        "tekst": "Solenergianlæg"
      },
      "8134": {
        "tekst": "Regnvands- og klimaanlæg"
      },
      "8140": {
        "tekst": "Trafikanlæg"
      },
      "8150": {
        "tekst": "Tekniske anlæg"
      },
      "8160": {
        "tekst": "Biogasanlæg"
      },
      "8170": {
        "tekst": "Landskabstekniske anlæg"
      },
      "8180": {
        "tekst": "Trafikanlæg"
      },
      "8181": {
        "tekst": "Vejanlæg"
      },
      "8182": {
        "tekst": "Parkeringsanlæg"
      },
      "8183": {
        "tekst": "Stianlæg"
      },
      "8185": {
        "tekst": "Lufthavn"
      },
      "8186": {
        "tekst": "Helikopterlandingsplads"
      },
      "8190": {
        "tekst": "Trafikterminal"
      },
      "8195": {
        "tekst": "Transportkorridor"
      },
      "9100": {
        "tekst": "Landområde"
      },
      "9110": {
        "tekst": "Jordbrugsområde"
      },
      "9120": {
        "tekst": "Naturområde"
      },
      "9125": {
        "tekst": "Faunapassage"
      },
      "9130": {
        "tekst": "Militære anlæg"
      },
      "9140": {
        "tekst": "Råstofindvinding"
      },
      "9150": {
        "tekst": "Landområder"
      },
      "9170": {
        "tekst": "Industrielt landbrug"
      },
      "9610": {
        "tekst": "Andet"
      }
    }
    if (anvendelseskoder[kode])
      return anvendelseskoder[kode]["tekst"]
    else
      return kode
  }
  getGenerelAnvendelsesTekstFraKode(kode) {
    const anvendelseskoder = {
      "11": {
        "tekst": "Boligområde"
      },
      "21": {
        "tekst": "Blandet bolig og erhverv"
      },
      "31": {
        "tekst": "Erhvervsområde"
      },
      "41": {
        "tekst": "Centerområde"
      },
      "61": {
        "tekst": "Sommerhusområde"
      },
      "71": {
        "tekst": "Område til offentlige formål"
      },
      "81": {
        "tekst": "Tekniske anlæg"
      },
      "91": {
        "tekst": "Landområde"
      },
      "96": {
        "tekst": "Andet"
      }
    }
    if (anvendelseskoder[kode])
      return anvendelseskoder[kode]["tekst"]
    else
      return kode
  }
}
