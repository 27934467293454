import ResultStore from "@septima/septima-search/searchers/local/ResultStore.js"

export default class LocalstorageResultStore extends ResultStore {
  constructor(options) {
    super(options)
    this.nameInStorage = "search_" + this.source
    let storedObject = this.getFromStorage(this.nameInStorage)
    this.data = storedObject.results
    this.data = this.adjustData(this.data)
  }

  //Interface methods
  //Context not used in Cookie implementation of ResultStore
  // 
  async put(resultDef, context) {
    let id = resultDef.source + "_" + resultDef.typeId + "_" + resultDef.resultid
    id = id.replace(/ /g, "")
    let existingResultDef = await this._find(id)

    if (existingResultDef) {
      existingResultDef.timestamp = resultDef.timestamp
      //TBD: sort now that timestamps have been updated
    } else {
      resultDef.id = id
      this.data.push(resultDef)
    }
    this.data = this.adjustData(this.data)
    this.saveToStorage(this.nameInStorage, {results: this.data})
  }

  async remove(id) {
    let existingResultDefIndex = this.data.findIndex(resultDef => resultDef.id == id)
    if (existingResultDefIndex > -1) {
      this.data.splice(existingResultDefIndex, 1)
      this.saveToStorage(this.nameInStorage, {results: this.data})
    }
  }

  //Context not used in Cookie implementation of ResultStore
  // 
  async getAll(context) {
    return this.data
  }

  
  //New internal

  getFromStorage (name) {
    if (this.isLocalStorageDefined()) {
      let json = localStorage.getItem('Septima_' + name)
      if (json !== null) {
        let object = JSON.parse(json)
        return object
      }
    }
    return {results: []}
  }

  saveToStorage (name, object) {
    if (this.isLocalStorageDefined()) {
      localStorage.setItem('Septima_' + name, JSON.stringify(object))
    }
  }

  removeFromStorage (name) {
    if (this.isLocalStorageDefined()) {
      localStorage.removeItem('Septima_' + name)
    }
  }

  isLocalStorageDefined () {
    if (typeof this._localStorageIsDefined !== 'undefined') {
      return this._localStorageIsDefined
    }
    try {
      this._localStorageIsDefined = (typeof localStorage !== 'undefined')
    } catch (e) {
      this._localStorageIsDefined = false
    }
    return this._localStorageIsDefined
  }
  
}